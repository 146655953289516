import { ExternalLink } from '../base/ExternalLink';
import { StaticImage } from 'gatsby-plugin-image';
import { LocationMarkerIcon, PhoneIcon } from '@heroicons/react/solid';
import React, { FunctionComponent, ReactElement } from 'react';

const Intro: FunctionComponent = (): ReactElement => (
  <article className='flex flex-col items-center mt-24 px-3 h-full'>
    <StaticImage
      src='../../images/logo-full.jpg'
      alt='Logo von Physioblock Bodytherapy'
      placeholder='blurred'
    />
    <p className='text-2xl text-grey font-headlines mt-5 text-center'>
      Privatpraxis<br />
      Physio- / Manualtherapeut<br />
      Sektoraler Heilpraktiker<br />
      Personal Trainer
    </p>
    <div className='flex flex-row items-center text-center text-md sm:text-xl mt-8 w-full sm:w-auto'>
      <div className='flex flex-col sm:flex-row items-center mr-4 sm:mr-8 w-1/2 sm:w-auto'>
        <PhoneIcon className='h-14 w-14 text-grey inline sm:float-left pr-2' />
        <p className='sm:text-left sm:inline sm:float-right'>
          <ExternalLink href='tel:+401797294050'>
            Termine unter <br />
            0179 / 729 40 50
          </ExternalLink>
        </p>
      </div>
      <div className='flex flex-col sm:flex-row items-center border-l border-l-gray-200 pl-6 sm:flex-1 sm:basis-0 w-1/2 sm:w-auto'>
        <LocationMarkerIcon className='h-14 w-14 text-grey inline sm:float-left pr-2' />
        <p className='sm:text-left sm:inline sm:float-right'>
          <ExternalLink
            target='_blank'
            href='https://g.page/blockhausfreiburg?share'
          >
            Im Blockhaus<br />
            Merdinger Weg 6<br />
            79111 Freiburg
          </ExternalLink>
        </p>
      </div>
    </div>
  </article>
);

export {
  Intro
};
